import useTranslate from '../hooks/useTranslate'
import NextErrorComponent from 'next/error'

import Page from '../containers/Page'
import {
  HeaderBlock
} from '../components/Blocks'
import H1 from '../components/Typography/H1'

import * as Sentry from '@sentry/nextjs'

const ErrorPage = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err)
  }
  const translate = useTranslate()

  const title = statusCode === 404
    ? translate('404_ERROR_TITLE')
    : translate('OTHER_ERROR_TITLE')

  return (
    <Page>
      <HeaderBlock showReturnToHomepage>
        <H1>{`${title} - ${statusCode || 500}`}</H1>
      </HeaderBlock>
    </Page>
  )
}

ErrorPage.getInitialProps = async (context) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps(context)
  const { res, err, asPath } = context
  errorInitialProps.hasGetInitialPropsRun = true

  if (res?.statusCode === 404) {
    return errorInitialProps
  }

  if (err) {
    Sentry.captureException(err)
    await Sentry.flush(2000)
  }
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
  )

  await Sentry.flush(2000)
  return {
    ...errorInitialProps,
    statusCode: res?.statusCode
  }
}

export default ErrorPage
