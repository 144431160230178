import styled from 'styled-components'
import { useLayout } from '../../hooks/resourceHooks'
import { hyperlink, grey, darkGrey } from '../../config/theme/colors'
import _ from 'lodash'
import Link from '../Link'

const BreadcrumbContainer = styled.nav`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  background-color: ${grey()};
`

const BreadcrumbItem = styled.li`
  margin: 0;
`

const BreadcrumbLink = styled(Link)`
  color: ${hyperlink()};
  font-size: 12px;
`

const BreadcrumbDivider = styled.span`
  padding-left: 5px;
  padding-right: 5px;
  color: rgb(120, 120, 120);
`

const FinalBreadcrumbItem = styled.li`
  font-size: 12px;
  color: gray;
  display: inline;
`

const BreadcrumbList = styled.ol`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
`
const Breadcrumb = ({ page }) => {
  const layout = useLayout()
  const mainNavigation = _.get(layout, 'mainNavigation.fields.pages')

  const getNavigationTree = ({ subpage = false, navigationTree, currentPageUrl, path = [] }) => {
    for (const page of navigationTree) {
      if (page.fields.pageUrlName === currentPageUrl && subpage) {
        return [...path, { title: page.fields.pageTitle, url: page.fields.pageUrlName }]
      }
      if (page.fields.subpages && page.fields.subpages.length > 0) {
        const subpagePath = getNavigationTree({
          subpage: true,
          navigationTree: page.fields.subpages,
          currentPageUrl,
          path: [
            ...path,
            { title: page.fields.pageTitle, url: page.fields.pageUrlName },
          ],
        })
  
        if (subpagePath.length > 0) {
          return subpagePath
        }
      }
    }
    return []
  }

  const breadcrumb = getNavigationTree({ navigationTree: mainNavigation, currentPageUrl: page })

  if (breadcrumb && breadcrumb.length > 0) {
    return (
      <BreadcrumbContainer aria-label="breadcrumbs">
        <BreadcrumbList>
        {breadcrumb.map((item, index) => (
          <BreadcrumbItem key={index}>
            {index > 0 && <BreadcrumbDivider aria-hidden='true'>/</BreadcrumbDivider>}
            {index === breadcrumb.length - 1 ? (
              <FinalBreadcrumbItem aria-current="location">{item.title}</FinalBreadcrumbItem>
            ) : (
              <BreadcrumbLink href={`/${item.url}`}>{item.title}</BreadcrumbLink>
            )}
          </BreadcrumbItem>
        ))}
        </BreadcrumbList>
      </BreadcrumbContainer>
    )
  } else {
    return null
  }
}

export default Breadcrumb
